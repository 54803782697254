import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlertLogData } from "../../../../actions/DocumentChainActions";

const useAlertData = (url) => {
    const [data, setData] = useState({ action: "", data: null });
    const alertLogData = useSelector((state) => state.alertLogData);
    const dispatch = useDispatch();

    useEffect(() => {
        if (data.action) {
            if (data.action === "setAlerts") {
                if (alertLogData?.length > 0)
                    dispatch(setAlertLogData([...alertLogData, data.data]));
                else dispatch(setAlertLogData([data.data]));
            } else if (data.action === "closeAlert") {
                let alertRegistryId = data.data;
                const filterdAlerts = alertLogData?.filter(
                    (alert) =>
                        alert.alertRegistryId !== alertRegistryId &&
                        alert.status !== "CLOSED"
                );
                if (filterdAlerts?.length > 0)
                    dispatch(setAlertLogData(filterdAlerts));
            } else if (data.action === "closeException") {
                let exceptionLogObjectId = data.data;
                const filterdAlerts = alertLogData?.filter(
                    (alert) =>
                        alert.exceptionLogObjectId !== exceptionLogObjectId &&
                        alert.status !== "CLOSED"
                );
                if (filterdAlerts?.length > 0)
                    dispatch(setAlertLogData(filterdAlerts));
            } else setData({ action: "", data: null });
        }
    }, [data]);

    return [data, setData];
};

export default useAlertData;
