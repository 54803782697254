import React from "react";
function WorkspaceCardItemView({
    workspaceData,
    renameWorkspace,
    deleteWorkspace,
}) {
    const isReadOnlyUser = sessionStorage.getItem("isReadOnlyUser") === "true";
    const handleRenameWorkspace = (workspaceData) => {
        renameWorkspace(workspaceData);
    };
    const handleDeleteWorkspace = (workspaceData) => {
        deleteWorkspace(workspaceData);
    };
    const sepretor = String.fromCharCode(1);

    return (
        <div
            className="cardRow flexAlignCenter"
            style={{ cursor: "default" }}
            id={"workspaceRow" + sepretor + workspaceData.workspaceName}
        >
            <div
                className="titleDiv textEllipsis mr-2 "
                style={{ width: "30%" }}
            >
                <span className="wkLogRowTitle m-0 w-100">
                    {workspaceData.workspaceName}
                </span>
            </div>
            <div className="btnDiv flexAlignCenter">
                <div
                    className={
                        isReadOnlyUser ||
                        workspaceData.workspaceType === "SYSTEM"
                            ? "flexAlignCenter doCenter readOnly"
                            : "flexAlignCenter cur_pointer doCenter"
                    }
                    title={"Rename workspace"}
                    id={
                        "btn_workspaceRename" +
                        sepretor +
                        workspaceData.workspaceName
                    }
                    onClick={(e) =>
                        isReadOnlyUser ||
                        workspaceData.workspaceType === "SYSTEM"
                            ? null
                            : handleRenameWorkspace(workspaceData)
                    }
                >
                    <img
                        src="./images/Rename.svg"
                        className={
                            isReadOnlyUser ||
                            workspaceData.workspaceType === "SYSTEM"
                                ? "renameIcon bw_plusButton readOnly"
                                : "renameIcon bw_plusButton"
                        }
                        id={
                            "btn_workspaceRename_icon_" +
                            sepretor +
                            workspaceData.workspaceName
                        }
                    ></img>
                    <span
                        className={
                            isReadOnlyUser ||
                            workspaceData.workspaceType === "SYSTEM"
                                ? "btn_title mr-3 readOnly"
                                : "btn_title mr-3"
                        }
                    >
                        Rename
                    </span>
                </div>
                <div
                    className={
                        isReadOnlyUser ||
                        workspaceData.workspaceType === "SYSTEM"
                            ? "addToBtn doCenter readOnly"
                            : "addToBtn cur_pointer doCenter"
                    }
                    title={"Delete Workspace"}
                    id={
                        "btn_workspaceDelete" +
                        sepretor +
                        workspaceData.workspaceName
                    }
                    onClick={(e) =>
                        isReadOnlyUser ||
                        workspaceData.workspaceType === "SYSTEM"
                            ? null
                            : handleDeleteWorkspace(workspaceData)
                    }
                >
                    <img
                        src="./images/trash 2.svg"
                        className={
                            isReadOnlyUser ||
                            workspaceData.workspaceType === "SYSTEM"
                                ? "deleteIcon bw_plusButton readOnly"
                                : "deleteIcon bw_plusButton"
                        }
                    ></img>
                    <span
                        className={
                            isReadOnlyUser ||
                            workspaceData.workspaceType === "SYSTEM"
                                ? "btn_title rreadOnly"
                                : "btn_title"
                        }
                    >
                        Delete
                    </span>
                </div>
            </div>
        </div>
    );
}

export default WorkspaceCardItemView;
