import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    setArchiveInfo,
    setAlertData,
    setAlertManager,
    setAlertsWidget,
    setActivePage,
} from "../../../../actions";
import {
    newMessage,
    callNotification,
} from "../../../../actions/WebSocketActions";
import {
    fetchIntegrations,
    reloadIntegrations,
    setIntegrations,
} from "../../../../actions/Integrations";
import http from "../../../../bae-client/HTTP";
import AlertManager from "../../../../bae-client/AlertManager";
import Snackbar from "../../../stylized/CustomSnackbar/CustomSnackbar";
import { setChatList } from "../../../../actions/CommunicatorActions";
import { reloadInformationFeed } from "../../../../actions/InformationFeedActions";
import AlertDialog from "../../../stylized/AlertDialog";
import CallAlert from "../ConfCall";
import TokenExpiredAlert from "./TokenExpiredAlert";
import {
    setEmailRecievedAction,
    setRefreshLocation,
    setMembersRefreshAction,
    fetchWorkspaceListAction,
} from "../../../../services/services/app";
import useAlertData from "./useAlertData";
import { setAlertLogData } from "../../../../actions/DocumentChainActions";
import Loader from "../../../stylized/Loader";

export default function Chat() {
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [showCallAlert, setShowCallAlert] = useState(false);
    const [showTokenExpiredAlert, setShowTokenExpiredAlert] = useState(false);
    const [popupShown, setPopupShown] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const [webSocket, setWebSocket] = useState(null);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [callNotificationData, setCallNotificationData] = useState(null);
    const setOpenDialog = (flag) => setOpen(flag);
    const dispatch = useDispatch();
    const archiveInfo = useSelector((state) => state.archiveInfo);
    const isIntegrationTokenExpired = useSelector((state) =>
        state.integrations.expiredRefreshTokensIntegrations &&
        state.integrations.expiredRefreshTokensIntegrations.length
            ? true
            : false
    );
    const [data, setData] = useAlertData();
    const [showLoader, setShowLoader] = useState(false);

    const refreshAlertAlerts = () => {
        let alertManager = new AlertManager();
        alertManager
            .loadDataByAPI()
            .then((response) => {
                let alertArray = [];
                let existingAlerts = alertManager.loadAllAlerts();
                let alertData = alertManager.getAllAlerts();
                if (
                    existingAlerts !== undefined &&
                    existingAlerts.length !== null &&
                    existingAlerts.length !== 0
                ) {
                    existingAlerts.forEach((item) => {
                        alertArray.push(item);
                    });
                    let archivedAlerts = alertManager.archivedAlerts;
                    let spaceInfo =
                        archiveInfo &&
                        archiveInfo.spaceInfo &&
                        archiveInfo.spaceInfo.length
                            ? archiveInfo.spaceInfo
                            : [];
                    let archiveInfoData = {
                        alertInfo: archivedAlerts,
                        spaceInfo: spaceInfo,
                    };
                    dispatch(setArchiveInfo(archiveInfoData));
                    dispatch(setAlertsWidget(alertArray));
                }
                dispatch(setAlertData(alertData));
            })
            .catch((errResp) => {
                setShowAlertDialog(true);
                setAlertDialogMessage(errResp.error);
                console.log(errResp);
            });
        dispatch(setAlertManager(alertManager));
    };
    function setAlerts(response) {
        setTimeout(() => {
            setData(() => {
                return { action: "setAlerts", data: response };
            });
        }, 500);
    }
    useEffect(() => {
        let ws = null;
        const url = "alert/list?status=OPEN";
        setShowLoader(true);
        http.sendRestRequest(url, "GET")
            .then((response) => {
                dispatch(setAlertLogData(response));
                setShowLoader(false);
            })
            .catch((error) => {
                setShowLoader(false);
                console.error("Error fetching alert data: ", error);
            });
        const connect = () => {
            let wsURL = http.getbaseurl().toString().replace("http", "ws");
            // url to test local on localhost bridge
            // let wsURL = "wss://eng.unitycentral.io/BW_UNITY_CENTRAL_QA/"
            ws = new WebSocket(
                `${wsURL}webSocket?Authorization=${sessionStorage.getItem(
                    "token"
                )}`
            );
            ws.onopen = (evt) => console.log("connected!");
            ws.onmessage = (evt) => {
                const url = window.location.pathname;
                const routeArray = url.split("/");
                const route = routeArray[routeArray.length - 1];
                const message = JSON.parse(evt.data);

                if (message.notificationType === "Flow.AttachToFlow") {
                    setMessage(message.message);
                    setOpenDialog(true);
                } else if (message.notificationType === "Alert.NewAlert") {
                    const splitArray = message.message.split(":");
                    const alertId =
                        splitArray.length > 1 ? splitArray[1] : null;
                    if (alertId) {
                        http.sendRestRequest(
                            `alert?alertRegistryId=${alertId}`
                        ).then((response) => {
                            if (response.status !== "CLOSED")
                                setAlerts(response);
                        });
                    }
                } else if (message.notificationType === "Alert.NewException") {
                    try {
                        const splitArray = message.message.split("|");
                        const exceptionTableId = splitArray[0].split(":")[1];
                        const exceptionId = splitArray[1].split(":")[1];
                        http.sendRestRequest(
                            `documentChain/exception?exceptionLogTblId=${exceptionTableId}&exceptionLogObjectId=${exceptionId}`
                        ).then((response) => {
                            if (response.status !== "CLOSED")
                                setAlerts(response);
                        });
                    } catch (error) {
                        console.log(error);
                    }
                } else if (
                    message.notificationType === "Alert.CloseException"
                ) {
                    let exceptionLogObjectId = parseInt(
                        message.message.split("|")[1].split(":")[1]
                    );
                    if (exceptionLogObjectId)
                        setData({
                            action: "closeException",
                            data: exceptionLogObjectId,
                        });
                } else if (message.notificationType === "Alert.CloseAlert") {
                    let alertId = parseInt(
                        message.message.split("|")[1].split(":")[1]
                    );
                    if (alertId)
                        setData({
                            action: "closeAlert",
                            data: alertId,
                        });
                } else if (
                    message.notificationType ===
                    "Integration.IntegrationSuccessful"
                ) {
                    dispatch(reloadIntegrations(message.message));
                } else if (
                    message.notificationType ===
                    "Integration.Outlook.RefreshTokenExpired"
                ) {
                    setShowTokenExpiredAlert(true);
                } else if (
                    message.notificationType === "Alert.RippleScheduler"
                ) {
                    setMessage(message.message);
                    refreshAlertAlerts();
                    setOpen(true);
                } else if (
                    message.notificationType ===
                    "Alert.Intersection.NewDocument"
                ) {
                    setOpen(true);
                    setMessage("Document related to flow found");
                } else if (
                    message.notificationType === "Integration.NewEmail"
                ) {
                    setOpen(true);
                    setMessage("You have received a new Email");
                    dispatch(reloadInformationFeed(true));
                    dispatch(setEmailRecievedAction(message.message));
                } else if (
                    message.notificationType === "Workspace.RefreshItem"
                ) {
                    setOpen(true);
                    const formattedMessage = message.message.split("|")[1];
                    const selectedValue = message.message.split("|")[0];

                    setMessage(formattedMessage);
                    const activeLoaction = {
                        workspaceId: parseInt(selectedValue),
                    };
                    if (route === "workspace")
                        dispatch(setRefreshLocation(activeLoaction));
                } else if (message.notificationType === "Workspace.Refresh") {
                    setOpen(true);
                    setMessage(message.message);
                    dispatch(fetchWorkspaceListAction());
                } else if (
                    message.notificationType === "Workspace.RefreshMembers"
                ) {
                    let workspaceId = message.message;
                    const activeMembers = {
                        workspaceId: parseInt(workspaceId),
                    };
                    dispatch(setMembersRefreshAction(activeMembers));
                } else if (message.notificationType === "Space.RefreshItem") {
                    setOpen(true);
                    const formattedMessage = message.message.split("|")[1];
                    const selectedValue = message.message.split("|")[0];
                    const spaceId = selectedValue.split("/")[1];
                    setMessage(formattedMessage);
                    const activeLoaction = {
                        spaceId: parseInt(spaceId),
                    };
                    if (route === "workspace")
                        dispatch(setRefreshLocation(activeLoaction));
                } else if (
                    message.notificationType === "Space.RefreshMembers"
                ) {
                    let spaceId = message.message;
                    const activeMembers = {
                        spaceId: parseInt(spaceId),
                    };
                    dispatch(setMembersRefreshAction(activeMembers));
                } else if (message.notificationType === "Folder.RefreshItem") {
                    setOpen(true);
                    const formattedMessage = message.message.split("|")[1];
                    const selectedValue = message.message.split("|")[0];
                    const locationIds = selectedValue.split("/");
                    const spaceId = locationIds[1];
                    const folderId = locationIds[locationIds.length - 1];
                    setMessage(formattedMessage);
                    const activeLoaction = {
                        spaceId: spaceId,
                        folderId: folderId,
                    };
                    if (route === "workspace")
                        dispatch(setRefreshLocation(activeLoaction));
                } else if (
                    message.notificationType === "Folder.RefreshMembers"
                ) {
                    let folderId = message.message;
                    const activeMembers = {
                        folderId: parseInt(folderId),
                    };
                    dispatch(setMembersRefreshAction(activeMembers));
                } else if (message.roomId) {
                    setCallNotificationData(message);
                    setShowCallAlert(true);
                } else {
                    dispatch(newMessage(message));
                    let url = "communicator/chatList";
                    http.sendRestRequest(url)
                        .then((res) => {
                            res.sort((a, b) => {
                                return (
                                    Date.parse(b.messageOn.split(".")[0]) -
                                    Date.parse(a.messageOn.split(".")[0])
                                );
                            });
                            dispatch(setChatList(res));
                        })
                        .catch((err) => {
                            console.log(err);
                            setShowAlertDialog(true);
                            setAlertDialogMessage(err.error);
                        });
                }
            };
            ws.onerror = () => {
                // console.log("error occurred!");
            };
            ws.onclose = function (e) {
                if (
                    sessionStorage.length &&
                    sessionStorage.getItem("token") &&
                    parseInt(sessionStorage.getItem("userId")) > 1
                )
                    setTimeout(() => {
                        connect();
                    }, 5000);
            };
            setWebSocket(ws);
            return () => {
                ws.close();
            };
        };
        connect();
        return () => {
            if (ws) ws.close();
        };
    }, []);

    useEffect(() => {
        if (!popupShown) setShowTokenExpiredAlert(isIntegrationTokenExpired);
        else setPopupShown(false);
    }, [isIntegrationTokenExpired]);

    const handleAccept = () => {
        dispatch(callNotification(callNotificationData));
        dispatch(setActivePage("Chat"));
        setShowCallAlert(false);
    };

    const handleDecline = () => {
        setShowCallAlert(false);
    };

    const handleIgnore = () => {
        setShowTokenExpiredAlert(false);
        setPopupShown(true);
        dispatch(fetchIntegrations());
    };
    const handleSingIn = () => {
        setShowTokenExpiredAlert(false);
    };

    return (
        <>
            <Snackbar
                open={open}
                setOpen={setOpenDialog}
                alertMessage={message}
            ></Snackbar>
            <AlertDialog
                open={showAlertDialog}
                message={alertDialogMessage}
                title={""}
                isAlert={false}
            ></AlertDialog>
            <CallAlert
                open={showCallAlert}
                handleAccept={handleAccept}
                handleDecline={handleDecline}
            ></CallAlert>
            <TokenExpiredAlert
                open={showTokenExpiredAlert}
                handleIgnore={handleIgnore}
                handleSingIn={handleSingIn}
            ></TokenExpiredAlert>
        </>
    );
}
